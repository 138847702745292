<template>
  <section class="overview--wrapper">
    <div class="subtitle--text my-3">{{ $t('general.startPrice') }} {{ price }}</div>
    <div class="btn btn-primary--outline" v-if="type">
      {{ type }}
    </div>
    <div class="overview--text mt-2">
      {{ $t('general.developer') }} <span v-html="developer"></span>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    developer: {
      type: String,
    },
    price: {
      type: String,
    },
    type: {
      type: String,
    },
  },
};
</script>
